
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
    name: "sign-in",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const submitButton = ref<HTMLElement | null>(null);

        //Create form validation object
        const login = Yup.object().shape({
            email: Yup.string().email().required().label("Email"),
            password: Yup.string().min(4).required().label("Password"),
        });

        //Form submit function
        const onSubmitLogin = (values) => {
            // Clear existing errors
            store.dispatch(Actions.LOGOUT);

            if (submitButton.value) {
                // Activate indicator
                submitButton.value.setAttribute("data-kt-indicator", "on");
            }

            // Dummy delay
            setTimeout(() => {
                // Send login request
                store
                    .dispatch(Actions.LOGIN, values)
                    .then(() => {
                        router.push({ name: "dashboard" });
                    })
                    .catch(() => {
                        let errorMsg = "Une erreur est survenue. Veuillez réessayer dans un instant.";

                        if (store.getters.getErrors.global) {
                            errorMsg = store.getters.getErrors.global;
                        }
                        Swal.fire({
                            text: errorMsg,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Fermer",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                //Deactivate indicator
                submitButton.value?.removeAttribute("data-kt-indicator");
            }, 2000);
        };

        return {
            onSubmitLogin,
            login,
            submitButton,
        };
    },
});
